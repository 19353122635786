








































































































































































import { Component, Watch } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import FilterBarReports from '@/components/reports/FilterBarReports.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import RatingReports from '@/components/reports/RatingReports.vue';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import { ChartData } from 'chart.js';
import BarChartMotivos from '@/components/reports/BarChartMotivos.vue';
import { IReportData } from '@/model/reports/IReportData';
import { IDataCard } from '@/model/reports/IDataCard';
import { IItemSelect } from '@/model/util/IItemSelect';
import { IEvCat } from '@/model/main/IEvCat';

@Component({
		name: 'MainReportsMotMora',
        components: {
			AppContainerBase,
			FilterBarReports,
			RatingReports,
			BarChartMotivos,
    }})
    export default class MainReportsMotMora extends MixinMain {
        public title = this.$t("MainMotivoMora.title");
		public subtitle = this.$t("MainMotivoMora.subtitle");
		public subtitle2 = this.$t("MainMotivoMora.subtitle2");
		public elementPerPage = this.$t("DataTable.elementPerPage");
		public countElementPage = this.$t("DataTable.countElementPage");
		public footertitle = this.$t("MainMotivoMora.footertitle");
        public isLoading = false;
		public itemsCampanias:Array<string> = [];
		public selectedCampania = '';
		public row_data:Array<IReportData> = [];
		public row_data2:any = {};
		public chartTitle = this.$t("MainMotivoMora.chartTitle");
		public chartSubtitle = this.$t("MainMotivoMora.chartSubtitle");
        public header: Array<IHeaderTable<IDataTable>> = [
			{ text: this.$t("MainMotivoMora.titleTable1") as string, value: 'name', align: 'start' },
			{ text: this.$t("MainMotivoMora.titleTable2") as string, value: 'total', align: 'end' }
		];

		@Watch('getFilter')
		public changedGetFilter(): void {
			this.getData();
		}
		mounted(): void {
			if (this.getFilter !== undefined) {
				this.getData();
			}
		}
		private getData(): void {
			this.isLoading = true;
			const request_1 = internet
				.newRequest()
				.get(`getMotivoMora?${this.getFilterUrl}`);
				Util.waitForPromises([request_1])
				.then((responses) => {
					const data = responses[0].data;
					this.itemsCampanias = Object.keys(data)
					console.log(data);
					this.row_data2 = {};
					this.addDataRow(data);
				})
				.catch(console.log)
				.finally(() => {
					this.isLoading = false;
					console.log("DataRows: ", this.row_data);
					console.log("DataRows2: ", this.row_data2);
				});
		}

		public addDataRow(response:any) {
			const data = [];
			for(let camp of Object.keys(response)) {
				//console.log("Agregando campaña ", camp);
				let current_data_cards:Array<IDataCard> = [];
				let current_data_chart:Array<number> = [];
				let current_labels_chart:Array<string> = [];
				let current_extra_data:Array<number> = [];
				let selectedCamp = response[camp];
				const dataTable: Array<IDataTable> = [];
				for (let item of Object.keys(selectedCamp.etiquetas)) {
					let porc = (selectedCamp.etiquetas[item] * 100) / selectedCamp.total;
					let card: IDataCard = {
						"nombre": item,
						"porcentaje": parseFloat(porc.toFixed(1)),
						"total": selectedCamp.etiquetas[item]
					};
					current_data_cards.push(card);

					const row: IDataTable = {};
					row['name'] = item;
					row['total'] = selectedCamp.etiquetas[item];
					dataTable.push(row);
					
					current_labels_chart.push(item);
					current_data_chart.push(parseFloat(porc.toFixed(1)));
					current_extra_data.push(selectedCamp.etiquetas[item])
				}
				const current_chart: ChartData = {
					labels: current_labels_chart,
					datasets: [
						{
							data: current_data_chart
						}
					],
				}

				let rowData: IReportData = {
					"data_cards": current_data_cards,
					"items": dataTable,
					"chart_data": current_chart,
					"extra_data": current_extra_data,
					"campania": camp,
				}
				data.push(rowData);
				this.row_data2[camp] = rowData;
			}
			this.row_data = data;
		}

		//public changeCampanias() {
		//	console.log(`emitiendo camp ${this.selectedCampania}`);
		//	this.$emit('changeEvent', this.selectedCampania);
		//}


		public getTextCountElementPerPage(
			to: number,
			end: number
		): string {
			let text = Util.replaceTextWith(
				this.countElementPage as string,
				'$1',
				to.toString()
			);
			text = Util.replaceTextWith(
				text,
				'$2',
				end.toString()
			);
			return text;
		}
    }
